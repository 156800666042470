export default [
  {
    path: '',
    name: 'home-customers',
    component: () => import('@/views/main/customers/Customers.vue'),
    redirect: { name: 'home-customers-create' },
    children: [
      {
        path: '',
        name: 'home-customers-create',
        component: () => import('@/views/main/customers/create/CustomerCreate.vue'),
        layout: 'Customer Create',
      },
      // {
      //   path: ':id',
      //   name: 'home-customers-view',
      //   component: () => import('@/views/main/customers/view/CustomerView.vue'),
      //   layout: 'Customer View',
      //   redirect: { name: 'home-customers-general-info' },
      // },
    ],
  },
]
